@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.ttf");
  font-display: swap;
}
$font: "Helvetica", sans-serif;

@font-face {
  font-family: "cerebrisans-bold";
  src: url("../fonts/cerebrisans//cerebrisans-bold.ttf");
}
$cerebrisans-bold-font: "cerebrisans-bold", sans-serif;

@font-face {
  font-family: "cerebrisans-book";
  src: url("../fonts/cerebrisans/cerebrisans-book.ttf");
}
$cerebrisans-book-font: "cerebrisans-book", sans-serif;

@font-face {
  font-family: "lato-bold";
  src: url("../fonts/lato-bold.ttf");
}
$lato-bold-font: "lato-bold", sans-serif;

@font-face {
  font-family: "cerebrisans-regular";
  src: url("../fonts/cerebrisans/cerebrisans-regular.ttf");
}
$cerebrisans-regular-font: "cerebrisans-regular", sans-serif;

@font-face {
  font-family: "fat-frank";
  src: url("../fonts/fat-frank.ttf");
}
$fat-frank-regular-font: "fat-frank", sans-serif;

body {
  margin: 0;
  font-family: $cerebrisans-regular-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
