//primary colors
$primary-color: #e7efff;

//secondary colors
$secondary-color: #34404a;

$text-color: #34404a;
$link-color: #34404a;

$form-control-text-color: #262626;

$blue-link-color: #36c0cf;

$password-eye-color: #cfcfcf;

$password-helper-text-color: #889c9a;

$password-helper-text-bullet-color: #e5e4df;

$sections-separator-color: #060808;

$progress-tracker-color: #838182;

$circular-progressbar-color: #36c0cf;

$circular-progressbar-before-after-color: #e5e4df;

$rectangle-container-border-color: #e2e1de;

$sidebar-background-color: #e5e4df;

$footer-bg: $primary-color;
$gray: #f5f5f5;

$black-1: #262626;

$footer-height: 70px;
$header-height: 80px;
$default-header-height: 88px;

$home-tools-headers: #707070;

$danger: #e74c3c;

$onboarding-labels: #34404a;
